import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

const Wrapper = styled.section`
.feature-benefit {
padding: 100px 0;
background-color: #f8f8f8;
}

.feature-benefit .circle-list {
list-style: circle;
margin: 0;
padding: 0 0 0 30px;
}

.feature-benefit .circle-list li {
margin: 10px 0;
font-size: 18px;
line-height: 30px;
}
._imagee{
height:20rem;
border:2px solid black;
border-radius:5px;
}
`;
const FeatureBenefit = () => {
    return (
        <Wrapper>
            <Container className='my-5'>
                <h2 className=" mb-3 text-center">Features &amp; Benefits</h2>
                <Row className="justify-content-center align-items-center m-auto">
                    <Col md={4} >
                        <h3>Features</h3>
                        <ul className="circle-list">
                            <li>State of the art facilities.</li>
                            <li>Highly qualified and experienced faculty members.</li>
                            <li>Wide range of academic programs available.</li>
                            <li>Individual attention to students.</li>
                            <li>Serene learning environment.</li>
                            <li>Reputation for excellent academic standars.</li>
                            <li>Guidance provided by the chief sir of the college.</li>
                        </ul>
                    </Col>
                    <Col md={4} >
                        <h3>Benefits</h3>
                        <ul className="circle-list">
                            <li>Enhanced learning experience and career opportunities.</li>
                            <li>Develop critical thinking and communication skills.</li>
                            <li>Highly academic standards ensure quality education for students.</li>
                            <li>Experienced and qualified faculty members provide expert guidance to student.</li>
                            <li>A serene learning environment enables students to focus on their studies and achieve their goals.</li>
                            <li>A wide range of courses caters to the diverse interests and career aspirations of students.</li>
                            <li>A chief sir og the college provides valuable guidance to students, helping them make informed deccision about their education and futuer career paths.</li>
                        </ul>
                    </Col>
                    <Col md={3}>
                        <img className="_imagee" src="./image/ba.jpg" alt="logo" />
                    </Col>
                </Row>
            </Container>
            <hr />
        </Wrapper>
    );
}

export default FeatureBenefit;