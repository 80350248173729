import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

function EmailForm() {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');

    const response = await fetch(`https://api.example.com/validate-email?email=${email}`);
    const data = await response.json();

    if (data.exists) {
      setErrorMessage('Email already exists');
    } else {
      // add code to submit the email address to your backend or database
      setEmail('');
      // setSuccessMessage('Email submitted successfully!');
    }
  };

  return (
    <div className='mx-2' style={{ padding: '2rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Form onSubmit={handleSubmit} style={{
        height: '250px', backgroundColor: 'gray',
        border: '3px solid purple', borderRadius: '20px', maxWidth: '50%', display: 'flex',
        flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
      }}>
        <Form.Label className='m-3' style={{ color: 'white' }}><h3 style={{ textAlign: 'center' }}>Wish to know more about admissions and updates? <br /> Drop your email.</h3></Form.Label>
        <Form.Group controlId="formBasicEmail" style={{ width: '75%' }}>
          <Form.Control style={{ fontSize: '30px' }}
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
        <Button style={{ fontSize: '30px', width: '200px', marginTop: '15px', paddingBottom: '5px' }} variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </div >
  );
}

export default EmailForm;
