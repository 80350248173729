import React from 'react';

const Card = ({ title, text }) => {
    return (
        <div style={{ width: '25%' }} className="card">
            <div style={{
                fontSize: "2rem",
                color: "green"
            }} className="d-flex align-content-center justify-content-center card-header">{title}</div>
            <div className="card-body px-1">{text}</div>
        </div>
    );
};

export default Card;
