import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import styled from 'styled-components';

const Wrapper = styled.section`
    .our-team .row {
        justify-content: center;
    }
    .__team{
        font-weight: 700;
        color: green;
    }
`

const teamMembers = [
    {
        name: "John Doe",
        imageUrl: "./image/c1.jpg",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae elit libero, a pharetra augue."
    },
    {
        name: "Jane Doe",
        imageUrl: "./image/c2.jpg",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae elit libero, a pharetra augue."
    },
    {
        name: "Bob Smith",
        imageUrl: "./image/c3.jpg",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae elit libero, a pharetra augue."
    }
];

const Team = () => {
    return (
        <Wrapper>
            <div className="d-flex justify-content-center align-items-center">
                <Container>
                    <h2 className="text-center mb-5 __team mt-3">Our Team</h2>
                    <Row className="justify-content-center">
                        {teamMembers.map((member) => (
                            <Col md={3} className="mb-5 mx-3" key={member.name}>
                                <Card className="h-100">
                                    <img className="pics" src={member.imageUrl} alt={member.name} />
                                    <Card.Body>
                                        <Card.Title>{member.name}</Card.Title>
                                        <Card.Text>{member.description}</Card.Text>
                                        <Button variant="primary">Know More</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
        </Wrapper>
    );
}

export default Team;
