import React from 'react'
import { Container, Row, Col, } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="mt-1" style={{ height: '40px' }}>
            <Container style={{ backgroundColor: 'yellowgreen' }} fluid={true}>
                <Row className="d-flex border-top align-items-center justify-content-around p-1 text-center">
                    <Col md={2} >
                        <NavLink className='mx-1' to="/">
                            <img style={{
                                maxHeight: '40px',
                                width: '110px'
                            }} className="logo" src="./image/Down-logo.png" alt="logo" />
                        </NavLink>
                    </Col>
                    <Col md={8}>
                        Copyright © 2023 Rampratap Ramprasad Tamang Janta Multiple Campus
                    </Col>
                    <Col md={2} className="d-flex justify-content-end">
                        Design By
                        <Link to={"https://www.facebook.com/princebindas.shah"} target='_blank' className="mx-1">Raju Sah</Link>
                    </Col>
                </Row>
            </Container>
        </footer >
    );
}

export default Footer