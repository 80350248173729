import React from 'react'
import Hero from '../components/Hero'
import Card from '../components/Card'
import Chief from '../components/Chief'
import Welcome from '../components/Welcome'
import Team from '../components/Team'
import FeatureBenefit from '../components/FeatureBenefit'
import PhotoGallery from '../components/PhotoGallery'
import EmailForm from '../components/EmailForm'

const Home = () => {
    return (
        <>

            <Hero />
            <Welcome />
            <Chief />
            <FeatureBenefit />
            <div className='d-flex gap-5 m-4'>
                <Card title="Welcome" text="Bachelor College is an undergraduate program that offers a comprehensive education in various fields such as business, science, arts, and more. It equips students with the knowledge, skills, and training required to succeed in their future careers." />
                <Card title="Our Mission" text="At Bachelor College, students benefit from a wide range of resources including state-of-the-art facilities, expert faculty members, and hands-on learning opportunities. The program also focuses on developing critical thinking, problem-solving, and leadership skills." />
                <Card title="Our Visoin" text="Bachelor College provides a supportive and inclusive environment that fosters personal growth and academic excellence. Students are encouraged to engage in extracurricular activities, research, and community service to enhance their learning experience and develop a well-rounded perspective." />
                <Card title="Our Core Value" text="Upon completion of the Bachelor College program, graduates are well-prepared to pursue advanced degrees, enter the workforce, or start their own business ventures. The program provides a solid foundation for lifelong learning and professional growth, enabling graduates to make meaningful contributions to society." />
            </div>
            <Team />
            <PhotoGallery />
            <EmailForm />
        </>
    )
}

export default Home