import React from 'react'
import Home from './pages/Home';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Footer from './components/Footer';
import About from './pages/About';
import Contact from './pages/Contact';
import Events from './pages/Events';
import Gallery from './pages/Gallery';
import Notice from './pages/Notice';
import Courses from './pages/Courses';
import NavComponent from './components/NavComponent';
import NoticeBoard from './components/NoticeBoard';
import CourseCatalog from './components/CourseCatalog';
import Header from './components/Header';


function App() {
  return (
    <Router>
      <NavComponent />
      <Header />
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/about" Component={About} />
        <Route path="contact" Component={Contact} />
        <Route path="/events" Component={Events} />
        <Route path="/gallery" Component={Gallery} />
        <Route path="/notices" Component={Notice} />
        <Route path="/notices/:id" Component={NoticeBoard} />
        <Route path="/courses" Component={Courses} />
        <Route path="/courses/:id" Component={CourseCatalog} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;


