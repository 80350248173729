import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Wrapper = styled.section`
.text-slider {
    width: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 30px;
  font-size: 1.2em;
  background-color: green;
  color: white;
}

.slide {
  margin: 0;
  padding: 0;
  animation: slide 20s linear infinite;
  font-weight: 600;
}

@keyframes slide {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}
.image_new{
    height: 25px;
    width: 50px;
    padding-bottom: 5px;
}
`

const Header = () => {
    const [news] = useState([
        { id: 1, title: "College announces new scholarship program" },
        { id: 2, title: "Record number of students enrolled this semester" },
        { id: 3, title: "Faculty member receives prestigious award" },
        { id: 4, title: "College partners with local businesses for internship program" },
        { id: 5, title: "Athletic teams earn multiple championship titles" }
    ]); // state to store news items
    const [index, setIndex] = useState(0); // state to store current index

    useEffect(() => {
        // set interval to change index every 3 seconds
        const intervalId = setInterval(() => {
            setIndex((index) => (index + 1) % news.length);
        }, 21000);
        return () => clearInterval(intervalId); // cleanup function to clear interval
    }, [news.length]);

    return (
        <Wrapper>

            <div className="text-slider">

                <p className="slide" style={{ animationDelay: '0s' }}><img className="image_new" src="./image/new-icon-gif-3.jpg" alt='New'></img>{news[index]?.title} </p> {/* display current news item */}
                <p className="slide" style={{ animationDelay: '0s' }}><img className="image_new" src="./image/new-icon-gif-3.jpg" alt='New'></img>{news[(index + 1) % news.length]?.title}</p> {/* display next news item */}
                <p className="slide" style={{ animationDelay: '0s' }}><img className="image_new" src="./image/new-icon-gif-3.jpg" alt='New'></img>{news[(index + 2) % news.length]?.title}</p> {/* display 2nd next news item */}
            </div>
        </Wrapper>
    );
};

export default Header;
