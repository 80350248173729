import React, { useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import styled from 'styled-components';

const Wrapper = styled.section`
    .__team{
        font-weight: 700;
        color: green;
    }
    .main{
        cursor: pointer;
    }
    .custom-link {
        text-decoration: none;
        color: inherit;
}
`
const notice = [
    {
        id: 1,
        title: "BBS",
        imageUrl: "./../image/course-1.jpg",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae elit libero, a pharetra augue."
    },
    {
        id: 2,
        title: "B-Ed",
        imageUrl: "./../image/course-2.jpg",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae elit libero, a pharetra augue."
    },
    {
        id: 3,
        title: "MBS",
        imageUrl: "./../image/course-3.jpg",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae elit libero, a pharetra augue."
    },
    {
        id: 4,
        title: "M-Ed",
        imageUrl: "./../image/course-4.jpg",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae elit libero, a pharetra augue."
    },
    {
        id: 4,
        title: "Arts",
        imageUrl: "./../image/course-5.jpg",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae elit libero, a pharetra augue."
    },
];

const CourseCatalog = () => {
    const [visibleNotices, setVisibleNotices] = useState(1);
    const [selectedCard, setSelectedCard] = useState(null);

    const handleLoadNext = () => {
        if (visibleNotices < notice.length) {
            setVisibleNotices(visibleNotices + 1);
        }
    }

    const handleLoadPrevious = () => {
        if (visibleNotices > 1) {
            setVisibleNotices(visibleNotices - 1);
        }
    }

    const handleClick = (id) => {
        setSelectedCard(id === selectedCard ? null : id);
    }

    const currentNotice = notice[visibleNotices - 1];

    return (
        <Wrapper>
            <div className=" d-flex justify-content-center align-items-center">
                <Container className='main'>
                    <h2 className="text-center mb-1 __team mt-3">Courses Details</h2>
                    <Row className="justify-content-center">
                        <Col md={5} className="mb-1 mx-3" key={currentNotice.title}>
                            <Card className="main" onClick={() => handleClick(currentNotice.id)}>
                                <Card.Img variant="top" src={currentNotice.imageUrl} />
                                <Card.Body>
                                    <Card.Title>{currentNotice.title}</Card.Title>
                                    <Card.Text>{currentNotice.description}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    {visibleNotices < notice.length &&
                        <div className="text-center mt-1">
                            <Button className='mx-2' variant="primary" size="lg" onClick={handleLoadPrevious}>Previous</Button>
                            <Button className='mx-2' variant="primary" size="lg" onClick={handleLoadNext}>Next</Button>
                        </div>
                    }
                </Container>
            </div>
        </Wrapper>
    );
}

export default CourseCatalog;
