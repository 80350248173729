import React, { useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.section`
    .__team{
        font-weight: 700;
        color: green;
    }
    .main{
        cursor: pointer;
    }
    .custom-link {
        text-decoration: none;
        color: inherit;
}
`
const notice = [
    {
        id: 1,
        title: "John Doe 16",
        imageUrl: "./image/c1.jpg",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae elit libero, a pharetra augue."
    },
    {
        id: 2,
        title: "Jane Doe 15",
        imageUrl: "./image/c2.jpg",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae elit libero, a pharetra augue."
    },
    {
        id: 3,
        title: "Bob Smith 14",
        imageUrl: "./image/c3.jpg",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae elit libero, a pharetra augue."
    },
    {
        id: 4,
        title: "John Doe 13",
        imageUrl: "./image/c1.jpg",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae elit libero, a pharetra augue."
    },
];



const Notice = () => {
    const [visibleNotices, setVisibleNotices] = useState(9);
    const [selectedCard, setSelectedCard] = useState(null);

    const handleLoadMore = () => {
        setVisibleNotices(visibleNotices + 9);
    }

    const handleClick = (id) => {
        setSelectedCard(id === selectedCard ? null : id);
    }

    return (
        <Wrapper>
            <div className="d-flex justify-content-center align-items-center">
                <Container className='main'>
                    <h2 className="text-center mb-5 __team mt-3">Notices</h2>
                    <Row className="justify-content-center">
                        {notice.slice(0, visibleNotices).map((member) => (
                            <Col md={3} className="mb-5 mx-3" key={member.title}>
                                <Link className="custom-link" to={`/notices/${member.id}`}>
                                    <Card className="main" onClick={() => handleClick(member.id)}>
                                        <Card.Img variant="top" src={member.imageUrl} />
                                        <Card.Body>
                                            <Card.Title>{member.title}</Card.Title>
                                            <Card.Text>{member.description}</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                        ))}
                    </Row>
                    {visibleNotices < notice.length &&
                        <div className="text-center mt-5">
                            <Button variant="primary" size="lg" onClick={handleLoadMore}>Load More</Button>
                        </div>
                    }
                </Container>
            </div>
        </Wrapper>
    );
}

export default Notice;
