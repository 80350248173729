import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
    .gallery__div{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        
    }
    .gallery_image {
      height: 300px;
      width: 350px;
      border: 2px solid black;
      border-radius: 10px;
      margin: 10px;
    }
  `
const PhotoGallery = () => {
    return (
        <Wrapper>
            <h2 className="text-center mb-5">Photo Gallery</h2>
            <div className='gallery__div'>
                <img className="gallery_image" src="./image/ba.jpg" alt="gallery1" />
                <img className="gallery_image" src="./image/ba.jpg" alt="gallery2" />
                <img className="gallery_image" src="./image/ba.jpg" alt="gallery3" />
                <img className="gallery_image" src="./image/ba.jpg" alt="gallery4" />
                <img className="gallery_image" src="./image/ba.jpg" alt="gallery5" />
                <img className="gallery_image" src="./image/ba.jpg" alt="gallery6" />
                <img className="gallery_image" src="./image/ba.jpg" alt="gallery7" />
                <img className="gallery_image" src="./image/ba.jpg" alt="gallery8" />
            </div>
        </Wrapper>
    );
};

export default PhotoGallery;
