import React, { Component } from 'react';
import styled from 'styled-components';
import { } from 'react-icons'
import { FaEnvelope, FaFacebookSquare, FaPhoneAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Wrapper = styled.section`
background-image: url('../image/bg-home.jpg');
background-size:cover;

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-section {
  flex: 1;
}

.right-section {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-weight: 700;
  color: navy;
}
.contact-item a {
  color: rgb(22, 0, 100);
  font-weight: 700;
}

.contact-item a:hover {
  text-decoration: underline;
}


.form-container {
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
}

.form {
  background-color:rgba(200, 200, 255, .4);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  max-width: 800px;
}

.form h2 {
  text-align: center;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
    width: 600px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

button[type="submit"] {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

button[type="submit"]:hover {
  background-color: #2E8B57;
}
.logo{
    height: 100px;
    width: 300px;
    margin-bottom:20px;
}


`

class Form extends Component {
    state = {
        name: '',
        email: '',
        contactNo: '',
        message: ''
    }

    handleSubmit = (event) => {
        event.preventDefault();
        // handle form submission logic here
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    render() {
        const { name, email, contactNo, message } = this.state;

        return (
            <Wrapper>
                <div style={{
                    maxWidth: '100vw',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: 'column',
                    textAlign: 'center',

                }}>
                    <div style={{
                        padding: '40px',
                        borderRadius: '10px',
                        boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)'
                    }}>
                        <h1 style={{ fontSize: '2.5rem', color: 'navy', fontWeight: '700' }}>Rampratap Ramprasad Tamang Janta Multiple College</h1>
                        <p style={{ fontSize: '1rem', marginBottom: '.5rem', color: 'navy', fontWeight: '700' }}>Asanpur-6, Golbazar (Siraha Nepal)</p>
                    </div>
                </div>
                <div className="form-container">
                    <form onSubmit={this.handleSubmit} className="form">
                        <h2 style={{ fontSize: '4rem', color: 'navy', fontWeight: "700", letterSpacing: '.5rem' }}>Contact Us</h2>
                        <div className="form-group">
                            <label htmlFor="name">Name:</label>
                            <input type="text" id="name" name="name" value={name} onChange={this.handleChange} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email:</label>
                            <input type="email" id="email" name="email" value={email} onChange={this.handleChange} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="contactNo">Contact No:</label>
                            <input type="tel" id="contactNo" name="contactNo" value={contactNo} onChange={this.handleChange} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Message:</label>
                            <textarea id="message" name="message" value={message} onChange={this.handleChange} required></textarea>
                        </div>
                        <button type="submit">Submit</button>
                    </form>
                </div>
                <hr />
                <div className="container">
                    <div className="left-section ">
                        <img className="logo" src="./image/Down-logo.png" alt="Logo" />
                    </div>
                    <div className="right-section">
                        <div className="contact-info">
                            <div className="contact-item">Email:
                                <Link to={"mailto:example@example.com"} target='_blank'>
                                    <FaEnvelope className='mx-2' size={16} color="blue"></FaEnvelope>
                                    email@example.com
                                </Link>
                            </div>
                            <div className="contact-item">
                                Contact :
                                <Link to={"tel:0000000000"}>
                                    <FaPhoneAlt className='mx-2' size={16} color="blue"></FaPhoneAlt>
                                    000-000-00000
                                </Link>
                            </div>
                            <div className="contact-item mb-4">
                                Facebook Link:
                                <Link to={"https://www.facebook.com/rrtjmcampus"} target='_blank'>
                                    <FaFacebookSquare className='mx-2' size={16} color="blue" />RRTJM
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>




            </Wrapper>
        );
    }
}

export default Form;
