import React from 'react';
import { NavLink } from 'react-bootstrap';
import styled from 'styled-components';

const Wrapper = styled.section`
    .__center{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
    }
    .title-message{
        width: 700px;
    }
    .image-Container{
     
    }
    .pics{
        height: 400px;
        border: 2px solid black;
        border-radius: 10%;
    }
    .chief-image h3{
        display:flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        color: blue;
        font-weight: 900; 
    }
    .chief-msg{
        text-align: center;
        color: green;
        font-weight: 700;
        text-decoration: underline;
    }
    hr{
        border: 1px solid black;
    }`;

const Chief = () => {
    return (
        <Wrapper>
            <div className='__center my-4'>
                <div className='card'>
                    <div className="title-message">
                        <h3 className='chief-msg'>MESSAGE FROM CAMPUS CHIEF</h3>
                        <p className='m-2'>As the Campus Chief of this esteemed institution, As we step into this new phase, we are determined to continue our legacy of academic excellence and strive towards the betterment of the college.
                            I urge all the students to focus on their studies and make the most of the opportunities provided by the college. This is the time to explore new horizons and broaden your knowledge. Our dedicated faculty is always ready to assist you in every possible way.
                            We understand that the past year has been challenging for everyone, and we have all gone through difficult times. However, I am proud of how the college community has come together to overcome these obstacles and adapt to the new normal.
                            We will continue to prioritize the health and safety of our students, faculty, and staff, and ensure that all necessary precautions are taken. We are also committed to providing the best possible learning experience to our students, whether it is through online classes or in-person interactions.
                            Once again, I welcome you all to the new academic session, and I look forward to seeing the college community grow and prosper together.</p>

                    </div>
                </div>
                <div className='chief-image'>
                    <div className="image-container mx-5">
                        <NavLink className='mx-3' to="/"><img className="pics" src="./image/chief.png" alt="logo" /></NavLink>
                    </div>
                    <h3>MR. KISHOR SAH</h3>
                </div>
            </div>
            <hr />
        </Wrapper>
    );
};

export default Chief;
