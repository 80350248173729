import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';

const Wrapper = styled.section`
    .welcome-section {
        background-color: #f9f9f9;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top:2rem;
      }
      `
const Welcome = () => {
    return (
        <Wrapper>
            <section className="welcome-section">
                <Container>
                    <h2 className="text-center mb-4">Welcome to our College!</h2>
                    <p className="text-center">Welcome to our college! We are thrilled to have you here and look forward
                        to supporting you on your academic journey. Our dedicated faculty and staff are committed to
                        providing you with a rich and rewarding learning experience. We offer a wide range of academic
                        programs and extracurricular activities that will help you develop your skills, knowledge, and talents.
                        Our campus is a vibrant community where you can connect with peers, explore new interests, and create
                        lifelong memories. We encourage you to take advantage of all the opportunities available to you and to
                        approach your studies with curiosity, enthusiasm, and a willingness to learn. We are here to help you
                        succeed, and we wish you all the best for a successful and fulfilling college experience.</p>
                </Container>
            </section>
        </Wrapper>
    );
}

export default Welcome;
