import React from 'react';
import heroImage from '../images/books.jpg';
import FeatureBenefit from '../components/FeatureBenefit';
import Card from '../components/Card';
import Team from '../components/Team';
import EmailForm from '../components/EmailForm';

const About = () => {
    return (
        <>
            <div
                style={{
                    backgroundImage: `url(${heroImage})`,
                    backgroundSize: 'cover',
                    height: '94vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'start'

                }}
            >


                <div style={{
                    maxWidth: '50%',
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: 'center',
                }}>
                    <h1 className='my-5' style={{ fontSize: '4rem', color: 'navy', fontWeight: "700", letterSpacing: '.5rem' }} >ABOUT US</h1>
                    <h2 style={{ fontSize: '2.5rem', color: 'blue', textAlign: 'center', }}>Rampratap Ramprasad Tamang Janta Multiple College</h2>
                    <p style={{ fontSize: '1rem', marginBottom: '.5rem', color: 'blue', textAlign: 'center', }}>Asanpur-6, Golbazar (Siraha Nepal)</p>
                    <p className='my-3' style={{ fontSize: '1.3rem', marginBottom: '2rem', color: 'blue', textAlign: 'center', }}>A hero image is a large, eye-catching image
                        that can be placed at the top of your page. This image can be of your team, your product, or your workspace, and
                        should set the tone for the rest of the page.</p>
                </div>
            </div>

            <FeatureBenefit />
            <Team />
            <div className='d-flex gap-5 m-4'>
                <Card title="Welcome" text="Bachelor College is an undergraduate program that offers a comprehensive education in various fields such as business, science, arts, and more. It equips students with the knowledge, skills, and training required to succeed in their future careers." />
                <Card title="Our Mission" text="At Bachelor College, students benefit from a wide range of resources including state-of-the-art facilities, expert faculty members, and hands-on learning opportunities. The program also focuses on developing critical thinking, problem-solving, and leadership skills." />
                <Card title="Our Visoin" text="Bachelor College provides a supportive and inclusive environment that fosters personal growth and academic excellence. Students are encouraged to engage in extracurricular activities, research, and community service to enhance their learning experience and develop a well-rounded perspective." />
                <Card title="Our Core Value" text="Upon completion of the Bachelor College program, graduates are well-prepared to pursue advanced degrees, enter the workforce, or start their own business ventures. The program provides a solid foundation for lifelong learning and professional growth, enabling graduates to make meaningful contributions to society." />
            </div>
            <EmailForm />
        </>
    )
}

export default About

