import React from 'react'
import { LinkContainer } from 'react-router-bootstrap';
import { Navbar, Nav, Container, } from 'react-bootstrap';
import { FaArrowCircleRight, FaFacebookSquare, FaInstagram, FaPhoneAlt } from 'react-icons/fa';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';


const Wrapper = styled.section`
    .logo{
        max-height:40px;
        width:150px;
    }
    .follow-link{
            text-decoration: none;
            color: Black;
        }
    .bg-change{
        background-color: whitesmoke;
    }
    .nav-link {
  text-decoration: none;
}

    .nav-link.active {
    text-decoration: underline 1.5px;
}
    `
const NavComponent = () => {
    return (
        <Wrapper className='position-sticky'>
            <Navbar expand="lg" className='bg-change'>
                <Container >
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <NavLink className='mx-3' to="/"><img className="logo" src="./image/Down-logo.png" alt="logo" /></NavLink>
                            <LinkContainer to="/">
                                <Nav.Link>Home</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/notices">
                                <Nav.Link>Notice</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/courses">
                                <Nav.Link>Courses</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/about">
                                <Nav.Link>About</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/gallery">
                                <Nav.Link>Gallery</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/contact">
                                <Nav.Link>Contact</Nav.Link>
                            </LinkContainer>
                            {/* <LinkContainer to="/events">
                                <Nav.Link>Events</Nav.Link>
                            </LinkContainer> */}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
                <Container className="d-flex justify-content-end">
                    <Link className='follow-link' to={"https://www.facebook.com/rrtjmcampus"} target='_blank'>
                        Follow on <FaArrowCircleRight />
                        <FaFacebookSquare className='mx-2' to={"/about"} size={25} color="blue" />
                    </Link>
                    <Link className='follow-link' to={"https://www.facebook.com/rrtjmcampus"} target='_blank'>
                        <FaInstagram className='mx-2' to={"/about"} size={25} color="blue" />
                    </Link>
                    <Link className='follow-link' to={"tel:0000000000"} target='_blank'>
                        <FaPhoneAlt className='mx-2 fa-phone' to={"/about"} size={25} color="blue" />
                    </Link>
                </Container>
            </Navbar >
        </Wrapper>
    )
}
export default NavComponent