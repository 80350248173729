import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import heroImage from '../images/bg-home.jpg';
import { Link } from 'react-router-dom';

function Hero() {
    const [content] = useState([
        'Discover your passion and make it your career with our diverse range of academic programs and resources.',
        'Join a community of like-minded individuals who are all driven towards success at our college.',
        `At our college we don't just teach you skills - we teach you how to apply them in the real world.`,
        'Make the most of your college experience with our vibrant extracurricular activities and events.',
        `Our college is more than just a campus - it's a place where you'll find your voice and learn to use it.`,
        'Take the first step towards your dream career with a world-class education at our college.',
        `Whether you're an artist scientist or entrepreneur our college has the resources you need to succeed.`,
        `At our college we believe that knowledge is power - and we're committed to empowering you to achieve your goals.`,
        'Join a legacy of successful alumni who have gone on to make a difference in the world after graduating from our college.',
        `With our experienced faculty and cutting-edge facilities there's no limit to what you can achieve at our college.`,
        'Our college is where ideas are born and dreams become reality - join us on this journey of discovery and growth.',
        'Unlock your potential and become the best version of yourself with a transformative education at our college.',
        `At our college we don't just teach you how to succeed - we teach you how to lead.'`,
        'Get the best of both worlds with a supportive community and world-class education at our college.',
        'Join a community of innovators and entrepreneurs who are shaping the future at our college.',
        `At our college we're committed to providing you with the skills and knowledge you need to thrive in the digital age.`,
        'Join a community of lifelong learners who are always pushing themselves to new heights at our college.',
        'Take advantage of our extensive network of industry connections and launch your career from our college.',
        `At our college we're more than just a school - we're a family.`,
        `With our comprehensive academic programs and resources there's no limit to what you can achieve at our college`,
        'Unlock your potential at our college - where academic excellence meets unparalleled opportunity.',
        'Join our vibrant community of learners and achievers and experience the best of what college life has to offer.',
        'At our college we believe in empowering our students to become leaders and change-makers of tomorrow. Join us on this journey to success.',
        'Discover a world of possibilities with our diverse range of academic programs extracurricular activities and resources that will help you achieve your dreams.',
        'At our college we are committed to providing you with a world-class education that will equip you with the skills and knowledge needed to thrive in the 21st century.',
        'Our college is more than just a place of learning - it\'s a community where you\'ll meet new people make lifelong friendships and create unforgettable memories.',
        'Get ready to be inspired challenged and transformed - our college is where you\'ll discover your true potential and achieve greatness.',
        'At our college we believe that education is the key to unlocking a brighter future. Join us today and embark on an unforgettable journey of discovery and growth.'
    ]);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex(prevIndex => (prevIndex + 1) % content.length);
        }, 5000);
        return () => clearInterval(interval);
    }, [content]);

    return (
        <>
            <div
                style={{
                    backgroundImage: `url(${heroImage})`,
                    backgroundSize: 'cover',
                    height: '94vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'start'

                }}
            >
                <div style={{
                    maxWidth: 700,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: 'center',
                }}>
                    <h1 style={{ fontSize: '2.5rem', color: 'white' }}>Rampratap Ramprasad Tamang Janta Multiple College</h1>
                    <p style={{ fontSize: '1rem', marginBottom: '.5rem', color: 'wheat', }}>Asanpur-6, Golbazar (Siraha Nepal)</p>
                    <p style={{ fontSize: '1.3rem', marginBottom: '2rem', color: 'wheat', }}><span id='change'>{content[index]}</span></p>
                    <Link to='/about'>
                        <Button variant="primary" size="lg" >
                            Know More
                        </Button>
                    </Link>
                </div>
            </div>
        </>
    );
}

export default Hero;
